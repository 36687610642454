import { Injectable } from '@angular/core';
import { lastValueFrom } from "rxjs";

import {Attachment, WebSocketMessage} from "../models/webSocketMessage";
import { MessageDisplayFormat } from "../models/messageDisplayFormat";
import { ConfigService } from "./config.service";
import { HttpService } from "./http.service";
import { AvatarInteractionService } from "./avatar-interaction.service";
import { LanguageService } from "./language.service";
import { MultimediaService } from "./multimedia.service";
import { WebSocketService } from './web-socket.service';

@Injectable({
    providedIn: 'root',
})
export class MessagesService {
    private defaultThumbColor: string = "rgba(49, 64, 90, 1)"
    private messageIndex: number | null = null;
    private welcomeMessage: string = 'Hello and welcome! As your dedicated assistant, I am here to support you with any inquiries you may have.';

    messages: MessageDisplayFormat[] = []

    constructor(
        private config: ConfigService,
        private http: HttpService,
        private visibility: AvatarInteractionService,
        private language: LanguageService,
        private multimedia: MultimediaService,
        private socket: WebSocketService) { }

    // getters
    getMessages(): MessageDisplayFormat[] {
        return this.messages
    }

    getMessageIndex(): number | null {
        return this.messageIndex ?? null;
    }

    getMessage(id: string) {
        return this.messages.find(m => m.id === id)
    }

    // setters
    setMessage (author: string, message: WebSocketMessage) {
        let m = this.createMessageDisplay(author, message)
        this.messages.push(m);
    }

    setWelcomeMessage(language: string) {
        if (!this.getMessage('welcome')) {
            this.setTextMEssage("welcome", this.language.getDesignTranslation(language).typography.welcome)
        }
    }

    setFrontendErrorMessage(language: string) {
        this.setTextMEssage("lost-net", this.language.getDesignTranslation(language).typography.lostNet)
    }

    setTextMEssage (id: string, content: string) {
        const messageObj: MessageDisplayFormat = {
            id: id,
            author: 'ai',
            content: content,
            language: 'en-US',
            direction: 'ltr',
            thumbsUpColor: this.defaultThumbColor,
            thumbsDownColor: this.defaultThumbColor
        };
        this.messages.push(messageObj);
    }

    setMessageFeedback (index: number, feedback: boolean, feedbackContent?: string) {
        const message = this.findMessageByIndex(index);
        if (message) {
            message.feedback = feedback;
            this.messages[index] = message;

            console.log(message)
            this.sendFeedback(message.id, message.feedback, feedbackContent).then();
        }
    }

    setMessageIndex(index: number) {
        this.messageIndex = index;
    }

    createMessageDisplay(author: string, message: WebSocketMessage) {
        let messageObj: MessageDisplayFormat = {
            id: message.id ?? "",
            author: author,
            content: message.content,
            language: message.language,
            direction: this.config.getDirectionFromLanguage(message.language),
            attachments: message.attachments,
            audioAnswerUrl: message.audioAnswer,
            thumbsUpColor: this.defaultThumbColor,
            thumbsDownColor: this.defaultThumbColor
    }
        return messageObj
    }

    clearMessages () {
        this.messages = [];
    }

    findMessageByIndex(index: number) {
        return this.messages.find((_, i) => i === index);
    }

    async createConversationRequest (type: string, currentInputValue: string, audioId?: string, file?: any) {
        this.config.setConversationTabStatusMode(true);
        this.visibility.showComponent("avatar-conversation-board")

        const message: WebSocketMessage = {
            type: type,
            content: currentInputValue,
            language: this.language.getSelectedLanguage()?.locale ?? "en-US"
        }

        if (type === "transcription") {
            message.content = audioId ?? "not found";
        } else if (type === "text") {
            message.attachments = [];

            if (file) {
                const attachment: Attachment = {
                    type: this.multimedia.getAttachmentType(file.name),
                    url: file.src
                };

                message.attachments.push(attachment);
            }

            this.setMessage("client", message);
            this.visibility.showComponent("avatar-loading")
            this.config.scrollToBottomEvent.emit();
        }

        try {
            const request = await this.submitConversationRequest(message);
            console.log(request)

            if (type === "transcription") {
                // @ts-ignore
                message.content = request.body.answer
                this.setMessage("client", message);
                this.visibility.showComponent("avatar-loading")
                this.config.scrollToBottomEvent.emit();
            }
        } catch (e: any) {
            console.log(e)
        }
    }

    async sendFeedback (message_id: string, message_feedback: boolean, message_feedback_text?: string) {
        try {
            return await lastValueFrom(
                this.http
                    .setHost(`${this.config.getHttpScheme()}://${this.config.getHost()}/messages/feedback`)
                    .setMethod("POST")
                    .setContent({
                        message_id: message_id,
                        is_positive: message_feedback,
                        feedback_content: message_feedback_text
                    })
                    .setHeaders({
                        "content-type": "application/json",
                        "Authorization": `Bearer ${this.config.getAnonymousId()}`
                    })
                    .create()
            );
        } catch (e: any) {
            throw new Error("Could not push feedback");
        }
    }

    async submitConversationRequest (data: WebSocketMessage) {
        try {
            this.socket.sendMessage(data);
        } catch (e: any) {
            if (e instanceof ProgressEvent || e.status === 0) {
                this.setFrontendErrorMessage(this.language.getSelectedLanguage().locale);
                this.visibility.hideComponent("avatar-loading");
                this.config.scrollToBottomEvent.emit();
                return;
            }

            throw e;
        }
    }

    async submitAudioData (audioData: File) {
        const formData = new FormData();
        formData.append("audio", audioData);

        try {
            return await lastValueFrom(
                this.http
                    .setHost(`${this.config.getHttpScheme()}://${this.config.getHost()}/agent/${this.config.getProjectId()}/audio`)
                    .setMethod("POST")
                    .setHeaders({
                        "Authorization": `Bearer ${this.config.getAnonymousId()}`
                    })
                    .setContent(formData)
                    .create()
            );
        } catch (e: any) {
            throw new Error(`could not submit audio data: ${e}`)
        }
    }
}
